var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card" }, [
      _c("div", { staticClass: "card-header" }, [
        _c("div", { staticClass: "card-header-title" }, [
          _c("img", { attrs: { src: "/images/favicon-32x32.png" } }),
          _vm._v("\n       \n      "),
          _c("h4", { staticClass: "title is-4" }, [
            _vm._v("\n        Is my data private?\n      ")
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "card-content" }, [
        _c("div", { staticClass: "content" }, [
          _c("p", [
            _vm._v(
              "\n        We are committed to protecting and respecting your privacy and the \n        security of your data. We comply with GDPR, CCPA, SB 190, SB 1392, and\n        we closely monitor changes to them. We follow industry best practices\n        for data encryption and backups.\n      "
            )
          ]),
          _vm._v(" "),
          _c("p", [
            _c("a", { attrs: { href: "/privacy-policy.html" } }, [
              _vm._v("How we safeguard your privacy")
            ]),
            _vm._v(".\n      ")
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }