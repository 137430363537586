var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showAd
    ? _c(
        "div",
        [
          _c("div", { staticClass: "ad-declaration" }, [
            _c(
              "a",
              {
                on: {
                  click: function($event) {
                    return _vm.toggleReportingBox()
                  }
                }
              },
              [_vm._v(_vm._s(_vm.$t("app.Report bad ad")))]
            ),
            _vm._v(" "),
            _c(
              "span",
              { staticStyle: { float: "right" } },
              [
                _vm._v(
                  "\n      " + _vm._s(_vm.$t("app.Close ad")) + "\n      "
                ),
                _c("b-button", {
                  staticStyle: { "margin-left": "10px" },
                  attrs: {
                    type: "is-light",
                    pack: "far",
                    "icon-left": "times-circle",
                    size: "is-small"
                  },
                  on: {
                    click: function($event) {
                      return _vm.hideAd()
                    }
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "ad-declaration" }, [
            _vm.showReporting
              ? _c("div", { staticClass: "card" }, [
                  _vm._m(0),
                  _vm._v(" "),
                  _vm._m(1)
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("desktopEnthusiastAd")
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-header" }, [
      _c("div", { staticClass: "card-header-title" }, [
        _vm._v("\n          How to report an inappropriate ad\n        ")
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-content" }, [
      _c("ol", [
        _c("li", [
          _vm._v(
            "\n            Take a screenshot of this page.\n            (Unsure how to take a screenshot?\n            "
          ),
          _c(
            "a",
            {
              attrs: {
                target: "_new",
                href: "https://www.take-a-screenshot.org/"
              }
            },
            [_vm._v("See instructions here")]
          ),
          _vm._v(".)\n          ")
        ]),
        _vm._v(" "),
        _c("li", [
          _vm._v("\n            Create an email with the subject line "),
          _c("b", [_vm._v("Bad ad")])
        ]),
        _vm._v(" "),
        _c("li", [
          _vm._v(
            "\n            Attach the screenshot to the email and send it to admin@wheelofnames.com.\n          "
          )
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }