var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "h3",
      {
        staticClass: "subtitle is-3",
        staticStyle: { "margin-bottom": "1rem" }
      },
      [
        _vm._v(_vm._s(_vm.wheelTitle) + "\n    "),
        _vm.editable
          ? _c(
              "span",
              { staticClass: "is-size-5", staticStyle: { color: "#CCC" } },
              [
                _c(
                  "b-tooltip",
                  {
                    attrs: {
                      label: _vm.$t("common.Edit title and description"),
                      type: "is-light",
                      position: "is-right"
                    }
                  },
                  [
                    _c("i", {
                      staticClass: "fas fa-edit clickable-icon",
                      on: {
                        click: function($event) {
                          return _vm.$emit("editTitleAndDescription")
                        }
                      }
                    })
                  ]
                )
              ],
              1
            )
          : _vm._e()
      ]
    ),
    _vm._v(" "),
    _c("p", [_vm._v("\n    " + _vm._s(_vm.wheelDescription) + "\n  ")]),
    _vm._v(" "),
    _c("br")
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }