var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", {
    staticClass: "textarea can-go-dark winner-textbox",
    attrs: { id: "winners", spellcheck: "false" }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }