var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "columns is-mobile is-vcentered",
        staticStyle: { "padding-top": "24px", color: "#888" }
      },
      [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "column is-narrow" },
          [
            _c("b-switch", {
              model: {
                value: _vm.sound,
                callback: function($$v) {
                  _vm.sound = $$v
                },
                expression: "sound"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _vm._m(1)
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "column has-text-right is-narrow",
        staticStyle: { "margin-right": "-10px" }
      },
      [
        _c("i", {
          staticClass: "fas fa-volume-mute",
          staticStyle: { "font-size": "150%" }
        })
      ]
    )
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "column is-narrow",
        staticStyle: { "margin-left": "-24px" }
      },
      [
        _c("i", {
          staticClass: "fas fa-volume-up",
          staticStyle: { "font-size": "150%" }
        })
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }