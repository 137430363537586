import { render, staticRenderFns } from "./wheelOverlayText.vue?vue&type=template&id=06713636&scoped=true"
import script from "./wheelOverlayText.vue?vue&type=script&lang=js"
export * from "./wheelOverlayText.vue?vue&type=script&lang=js"
import style0 from "./wheelOverlayText.vue?vue&type=style&index=0&id=06713636&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06713636",
  null
  
)

export default component.exports