var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      on: {
        click: function($event) {
          return _vm.$emit("click")
        }
      }
    },
    [
      !_vm.isTouchScreen
        ? _c(
            "div",
            { ref: "instructionsLayer", attrs: { id: "instructionsLayer" } },
            [
              _c(
                "div",
                {
                  staticClass: "instructionsText",
                  staticStyle: { "padding-top": "20%" },
                  attrs: { id: "topInstruction" }
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("spinningwheel.Click to spin")) +
                      "\n    "
                  )
                ]
              ),
              _vm._v(" "),
              !_vm.isSafari
                ? _c(
                    "div",
                    {
                      staticClass: "instructionsText",
                      staticStyle: { "padding-top": "60%" },
                      attrs: { id: "bottomInstruction" }
                    },
                    [
                      _vm._v(
                        "\n      " +
                          _vm._s(_vm.$t("spinningwheel.or press ctrl+enter")) +
                          "\n    "
                      )
                    ]
                  )
                : _vm._e()
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isTouchScreen
        ? _c(
            "div",
            { ref: "instructionsLayer", attrs: { id: "instructionsLayer" } },
            [
              _c(
                "div",
                {
                  staticClass: "instructionsText",
                  staticStyle: { "padding-top": "20%" },
                  attrs: { id: "topInstruction" }
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("spinningwheel.Tap to spin")) +
                      "\n    "
                  )
                ]
              )
            ]
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }