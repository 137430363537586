var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card" }, [
      _c("div", { staticClass: "card-header" }, [
        _c("div", { staticClass: "card-header-title" }, [
          _c("img", { attrs: { src: "/images/favicon-32x32.png" } }),
          _vm._v("\n       \n      "),
          _c("h4", { staticClass: "title is-4" }, [
            _vm._v("\n        How to use the wheel spinner\n      ")
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "card-content" }, [
        _c("div", { staticClass: "content" }, [
          _c("p", [
            _vm._v(
              "\n        It's easy: type in your entries in the textbox to the right of the\n        wheel, then click the wheel to spin it and get a random winner.\n        To make the wheel your own by customizing the colors, sounds, and spin\n        time, click\n        "
            ),
            _c("span", { staticClass: "tag is-info" }, [
              _c("i", { staticClass: "fas fa-palette" }),
              _vm._v(" Customize")
            ]),
            _vm._v("\n        at the top of the page.\n      ")
          ]),
          _vm._v(" "),
          _c("p", [
            _c("a", { attrs: { href: "/user-reviews-and-tutorials" } }, [
              _vm._v(
                "\n          Video reviews and tutorials by users.\n        "
              )
            ])
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }