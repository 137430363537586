var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", {
    staticClass: "textarea can-go-dark basic-editor has-fixed-size",
    attrs: { id: "entries", spellcheck: "false", contentEditable: "true" },
    on: {
      paste: _vm.onPaste,
      input: function($event) {
        return _vm.setEntries()
      },
      keyup: _vm.IE_setEntries
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }