var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "card" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "card-content" }, [
      _c("div", { staticClass: "content" }, [
        _c("p", [
          _vm._v(
            "\n        We are proud to share that 100% of the electricity that powers our\n        servers is renewable, and 93% comes from carbon-free energy sources.\n      "
          )
        ]),
        _vm._v(" "),
        _c(
          "p",
          [
            _c("router-link", { attrs: { to: "/faq/sustainability" } }, [
              _vm._v("Sustainability")
            ]),
            _vm._v(".\n      ")
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-header" }, [
      _c("div", { staticClass: "card-header-title" }, [
        _c("img", { attrs: { src: "/images/favicon-32x32.png" } }),
        _vm._v("\n       \n      "),
        _c("h4", { staticClass: "title is-4" }, [
          _vm._v("\n        Does Wheel of Names use renewable energy?\n      ")
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }