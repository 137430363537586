var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "section" }, [
    _c("div", { staticClass: "columns" }, [
      _c(
        "div",
        { staticClass: "column" },
        [
          _c("whatIsItForCard"),
          _vm._v("\n       \n      "),
          _c("sustainabilityCard")
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "column" },
        [_c("howToUseCard"), _vm._v("\n       \n      "), _c("statsCard")],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "column" },
        [
          _c("isMyDataPrivateCard"),
          _vm._v("\n       \n      "),
          _c("canTheWheelBeRiggedCard")
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }