var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "card can-go-dark",
      staticStyle: { "margin-top": "10px", "border-radius": "5px" }
    },
    [
      _c(
        "div",
        { staticClass: "card-content", staticStyle: { padding: "10px" } },
        [
          _c("div", { staticClass: "content" }, [
            _vm.appInfoVisible
              ? _c(
                  "div",
                  [
                    _c("b-button", {
                      staticStyle: {
                        float: "right",
                        "margin-top": "-1px",
                        "border-radius": "7px"
                      },
                      attrs: {
                        type: "is-light",
                        pack: "fas",
                        "icon-left": "chevron-circle-down",
                        size: "is-small"
                      },
                      on: { click: _vm.toggleVisibility }
                    }),
                    _vm._v(" "),
                    _c(
                      "span",
                      { staticStyle: { color: "#999" } },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("appInfo.Version")) +
                            " " +
                            _vm._s(_vm.version) +
                            "\n          "
                        ),
                        _c(
                          "router-link",
                          { attrs: { to: "/faq/changelog" } },
                          [
                            !_vm.userHasSeenCurrentVersion
                              ? _c(
                                  "b-tag",
                                  {
                                    attrs: {
                                      type: "is-light is-rounded",
                                      rounded: ""
                                    }
                                  },
                                  [_vm._v("\n              New!\n            ")]
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticStyle: { float: "right", "margin-right": "10px" }
                      },
                      [
                        _c("router-link", { attrs: { to: "/faq/changelog" } }, [
                          _vm._v("\n            Changelog\n          ")
                        ])
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("hr", {
                      staticStyle: {
                        "margin-top": "0.5em",
                        "margin-bottom": "4px"
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "b-carousel",
                      {
                        attrs: {
                          arrow: false,
                          "pause-info": false,
                          indicator: false
                        },
                        model: {
                          value: _vm.shownSlide,
                          callback: function($$v) {
                            _vm.shownSlide = $$v
                          },
                          expression: "shownSlide"
                        }
                      },
                      [
                        _c(
                          "b-carousel-item",
                          { staticStyle: { height: "150px" } },
                          [
                            _c(
                              "section",
                              { staticClass: "is-medium is-white" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "has-text-centered" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: { "margin-bottom": "12px" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                  This is the old version of wheelofnames.com. Please tell us\n                  why you prefer this version over the new one.\n                "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      [
                                        _c(
                                          "b-button",
                                          {
                                            attrs: {
                                              type: "is-primary",
                                              pack: "fa",
                                              "icon-left": "comment"
                                            },
                                            on: { click: _vm.openFeedbackForm }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("toolbar.Feedback"))
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "b-button",
                                          {
                                            attrs: {
                                              type: "is-primary",
                                              pack: "fa",
                                              "icon-left": "arrow-left",
                                              onclick:
                                                "location.href='https://wheelofnames.com'"
                                            }
                                          },
                                          [_vm._v("Go back")]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ]
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _c(
                  "div",
                  [
                    _c("b-button", {
                      staticStyle: {
                        float: "right",
                        "margin-top": "-1px",
                        "border-radius": "7px"
                      },
                      attrs: {
                        type: "is-light",
                        pack: "fas",
                        "icon-left": "chevron-circle-up",
                        size: "is-small"
                      },
                      on: { click: _vm.toggleVisibility }
                    }),
                    _vm._v(" "),
                    _c(
                      "span",
                      { staticStyle: { color: "#999" } },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("appInfo.Version")) +
                            " " +
                            _vm._s(_vm.version) +
                            "\n          "
                        ),
                        _c(
                          "router-link",
                          { attrs: { to: "/faq/changelog" } },
                          [
                            !_vm.userHasSeenCurrentVersion
                              ? _c(
                                  "b-tag",
                                  {
                                    attrs: {
                                      type: "is-light is-rounded",
                                      rounded: ""
                                    }
                                  },
                                  [_vm._v("\n              New!\n            ")]
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticStyle: { float: "right", "margin-right": "10px" }
                      },
                      [
                        _c("router-link", { attrs: { to: "/faq/changelog" } }, [
                          _vm._v("\n            Changelog\n          ")
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }