var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-tag",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.number > 0,
          expression: "number>0"
        }
      ],
      attrs: { rounded: "" }
    },
    [_vm._v(_vm._s(_vm.number.toLocaleString()))]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }