var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        active: _vm.optionsDialogVisible,
        width: 640,
        scroll: "keep",
        "full-screen": _vm.$mq == "mobile"
      },
      on: {
        "update:active": function($event) {
          _vm.optionsDialogVisible = $event
        }
      }
    },
    [
      _c("div", { staticClass: "modal-card", staticStyle: { width: "auto" } }, [
        _c(
          "section",
          { staticClass: "modal-card-body can-go-dark" },
          [
            _c(
              "b-tabs",
              {
                attrs: { type: "is-boxed", size: "is-small" },
                model: {
                  value: _vm.activeTab,
                  callback: function($$v) {
                    _vm.activeTab = $$v
                  },
                  expression: "activeTab"
                }
              },
              [
                _c(
                  "b-tab-item",
                  { attrs: { label: _vm.$t("optionsdialog.During spin") } },
                  [
                    _c("br"),
                    _vm._v(" "),
                    _c("duringSpinSoundPicker", {
                      attrs: {
                        duringSpinSound: _vm.wheelConfig.duringSpinSound,
                        duringSpinSoundVolume:
                          _vm.wheelConfig.duringSpinSoundVolume
                      },
                      on: {
                        "set-during-spin-sound": _vm.setDuringSpinSound,
                        "set-during-spin-sound-volume":
                          _vm.setDuringSpinSoundVolume
                      }
                    }),
                    _vm._v(" "),
                    _c("hr"),
                    _vm._v(" "),
                    _c(
                      "b-checkbox",
                      {
                        model: {
                          value: _vm.wheelConfig.allowDuplicates,
                          callback: function($$v) {
                            _vm.$set(_vm.wheelConfig, "allowDuplicates", $$v)
                          },
                          expression: "wheelConfig.allowDuplicates"
                        }
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("optionsdialog.Display duplicates")) +
                            "\n            "
                        ),
                        _c("tooltip", {
                          attrs: {
                            title: _vm.$t("optionsdialog.Display every entry")
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "b-checkbox",
                      {
                        staticStyle: { "margin-left": "10px" },
                        model: {
                          value: _vm.wheelConfig.slowSpin,
                          callback: function($$v) {
                            _vm.$set(_vm.wheelConfig, "slowSpin", $$v)
                          },
                          expression: "wheelConfig.slowSpin"
                        }
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("optionsdialog.Spin slowly")) +
                            "\n          "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "b-checkbox",
                      {
                        staticStyle: { "margin-left": "10px" },
                        model: {
                          value: _vm.wheelConfig.showTitle,
                          callback: function($$v) {
                            _vm.$set(_vm.wheelConfig, "showTitle", $$v)
                          },
                          expression: "wheelConfig.showTitle"
                        }
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("optionsdialog.Show title")) +
                            "\n          "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("hr"),
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("optionsdialog.Spin time (seconds)")) +
                        "\n          "
                    ),
                    _c(
                      "b-slider",
                      {
                        attrs: { min: 1, max: 60 },
                        model: {
                          value: _vm.wheelConfig.spinTime,
                          callback: function($$v) {
                            _vm.$set(_vm.wheelConfig, "spinTime", $$v)
                          },
                          expression: "wheelConfig.spinTime"
                        }
                      },
                      [
                        _vm._l([10, 20, 30, 40, 50, 60], function(val) {
                          return [
                            _c(
                              "b-slider-tick",
                              { key: val, attrs: { value: val } },
                              [_vm._v(_vm._s(val))]
                            )
                          ]
                        })
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c("hr"),
                    _vm._v(" "),
                    _c("b", [
                      _vm._v(_vm._s(_vm.$t("optionsdialog.Max names on wheel")))
                    ]),
                    _vm._v(" "),
                    _vm.entriesHaveUnequalWeight
                      ? _c("tooltip", {
                          attrs: {
                            title: _vm.$t(
                              "optionsdialog.Disabled because the entries"
                            )
                          }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _c("small", [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.$t("optionsdialog.All names in the text-box")
                          ) +
                          "\n          "
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "b-slider",
                      {
                        attrs: {
                          min: 4,
                          max: 1000,
                          disabled: _vm.entriesHaveUnequalWeight
                        },
                        model: {
                          value: _vm.wheelConfig.maxNames,
                          callback: function($$v) {
                            _vm.$set(_vm.wheelConfig, "maxNames", $$v)
                          },
                          expression: "wheelConfig.maxNames"
                        }
                      },
                      [
                        _vm._l(
                          [100, 200, 300, 400, 500, 600, 700, 800, 900, 1000],
                          function(val) {
                            return [
                              _c(
                                "b-slider-tick",
                                { key: val, attrs: { value: val } },
                                [_vm._v(_vm._s(val))]
                              )
                            ]
                          }
                        )
                      ],
                      2
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-tab-item",
                  { attrs: { label: _vm.$t("optionsdialog.After spin") } },
                  [
                    _c("br"),
                    _vm._v(" "),
                    _c("afterSpinSoundPicker", {
                      attrs: {
                        afterSpinSound: _vm.wheelConfig.afterSpinSound,
                        afterSpinSoundVolume:
                          _vm.wheelConfig.afterSpinSoundVolume,
                        firstTextOnWheel: _vm.firstTextOnWheel
                      },
                      on: {
                        "set-after-spin-sound": _vm.setAfterSpinSound,
                        "set-after-spin-sound-volume":
                          _vm.setAfterSpinSoundVolume
                      }
                    }),
                    _vm._v(" "),
                    _c("hr"),
                    _vm._v(" "),
                    _c(
                      "b-checkbox",
                      {
                        model: {
                          value: _vm.wheelConfig.animateWinner,
                          callback: function($$v) {
                            _vm.$set(_vm.wheelConfig, "animateWinner", $$v)
                          },
                          expression: "wheelConfig.animateWinner"
                        }
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$t("optionsdialog.Animate winning entry")
                            ) +
                            "\n          "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("br"),
                    _c("br"),
                    _vm._v(" "),
                    _c(
                      "b-checkbox",
                      {
                        model: {
                          value: _vm.wheelConfig.launchConfetti,
                          callback: function($$v) {
                            _vm.$set(_vm.wheelConfig, "launchConfetti", $$v)
                          },
                          expression: "wheelConfig.launchConfetti"
                        }
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("optionsdialog.Launch confetti")) +
                            "\n          "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("br"),
                    _c("br"),
                    _vm._v(" "),
                    _c(
                      "b-checkbox",
                      {
                        model: {
                          value: _vm.wheelConfig.autoRemoveWinner,
                          callback: function($$v) {
                            _vm.$set(_vm.wheelConfig, "autoRemoveWinner", $$v)
                          },
                          expression: "wheelConfig.autoRemoveWinner"
                        }
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$t(
                                "optionsdialog.Auto-remove winner after 5 seconds"
                              )
                            ) +
                            "\n          "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("br"),
                    _c("br"),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "columns",
                        staticStyle: { "margin-bottom": "0" }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "column is-narrow-desktop" },
                          [
                            _c(
                              "b-checkbox",
                              {
                                model: {
                                  value: _vm.wheelConfig.displayWinnerDialog,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.wheelConfig,
                                      "displayWinnerDialog",
                                      $$v
                                    )
                                  },
                                  expression: "wheelConfig.displayWinnerDialog"
                                }
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.$t(
                                        "optionsdialog.Display popup with message:"
                                      )
                                    ) +
                                    "\n              "
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "column",
                            staticStyle: { padding: "0", "margin-top": "5px" }
                          },
                          [
                            _c("b-input", {
                              attrs: {
                                disabled: !_vm.wheelConfig.displayWinnerDialog,
                                maxlength: "100"
                              },
                              model: {
                                value: _vm.wheelConfig.winnerMessage,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.wheelConfig,
                                    "winnerMessage",
                                    $$v
                                  )
                                },
                                expression: "wheelConfig.winnerMessage"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "margin-top": "-12px",
                          "margin-bottom": "12px"
                        }
                      },
                      [
                        _c(
                          "b-checkbox",
                          {
                            staticStyle: { "margin-left": "25px" },
                            attrs: {
                              disabled: !_vm.wheelConfig.displayWinnerDialog
                            },
                            model: {
                              value: _vm.wheelConfig.displayRemoveButton,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.wheelConfig,
                                  "displayRemoveButton",
                                  $$v
                                )
                              },
                              expression: "wheelConfig.displayRemoveButton"
                            }
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.$t("optionsdialog.Display Remove button")
                                ) +
                                "\n            "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v(" "),
                        _vm.wheelConfig.isAdvanced
                          ? _c(
                              "b-checkbox",
                              {
                                staticStyle: { "margin-left": "25px" },
                                attrs: {
                                  disabled: !_vm.wheelConfig.displayWinnerDialog
                                },
                                model: {
                                  value: _vm.wheelConfig.displayHideButton,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.wheelConfig,
                                      "displayHideButton",
                                      $$v
                                    )
                                  },
                                  expression: "wheelConfig.displayHideButton"
                                }
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "optionsdialog.Display Hide button"
                                      )
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "b-checkbox",
                      {
                        model: {
                          value: _vm.wheelConfig.playClickWhenWinnerRemoved,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.wheelConfig,
                              "playClickWhenWinnerRemoved",
                              $$v
                            )
                          },
                          expression: "wheelConfig.playClickWhenWinnerRemoved"
                        }
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$t(
                                "optionsdialog.Play click sound when the winner is removed"
                              )
                            ) +
                            "\n          "
                        )
                      ]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-tab-item",
                  {
                    staticStyle: { height: "500px" },
                    attrs: { label: _vm.$t("optionsdialog.Appearance") }
                  },
                  [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.browserIsIE,
                            expression: "!browserIsIE"
                          }
                        ],
                        staticClass: "columns is-mobile is-vcentered"
                      },
                      [
                        _c("div", { staticClass: "column has-text-right" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("optionsdialog.One color")) +
                              "\n            "
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "column is-narrow" }, [
                          _c("img", {
                            staticClass: "wheel-type-image",
                            attrs: { src: "/images/color-wheel.png" }
                          })
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "column is-narrow" },
                          [
                            _c("b-switch", {
                              attrs: {
                                "passive-type": "is-primary",
                                type: "is-primary"
                              },
                              model: {
                                value: _vm.isImageWheel,
                                callback: function($$v) {
                                  _vm.isImageWheel = $$v
                                },
                                expression: "isImageWheel"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "column is-narrow",
                            staticStyle: { "margin-left": "-20px" }
                          },
                          [
                            _c("img", {
                              staticClass: "wheel-type-image",
                              attrs: {
                                src: "/images/chocolate-chip-cookie.jpg"
                              }
                            })
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "column" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.$t("optionsdialog.Wheel background image")
                              ) +
                              "\n            "
                          )
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c("hr"),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.wheelConfig.type == "color",
                            expression: "wheelConfig.type=='color'"
                          }
                        ]
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "columns",
                            staticStyle: { "margin-bottom": "10px" }
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "column" },
                              [
                                _c("colorThemeSelector", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: !_vm.browserIsIE,
                                      expression: "!browserIsIE"
                                    }
                                  ],
                                  attrs: {
                                    disabled: _vm.wheelConfig.isAdvanced
                                  },
                                  on: { "set-color-theme": _vm.setColorTheme }
                                })
                              ],
                              1
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "columns",
                            staticStyle: { "margin-bottom": "0px" }
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "column is-one-third" },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.$t("optionsdialog.Customize colors")
                                    ) +
                                    " \n                "
                                ),
                                _c("tooltip", {
                                  attrs: {
                                    title: _vm.$t(
                                      "optionsdialog.Colors tooltip"
                                    )
                                  }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "column" }, [
                              _c(
                                "div",
                                { staticClass: "color-grid" },
                                _vm._l(_vm.wheelConfig.colorSettings, function(
                                  setting,
                                  index
                                ) {
                                  return _c(
                                    "div",
                                    { key: index },
                                    [
                                      _c("b-checkbox", {
                                        attrs: {
                                          disabled: _vm.wheelConfig.isAdvanced
                                        },
                                        model: {
                                          value: setting.enabled,
                                          callback: function($$v) {
                                            _vm.$set(setting, "enabled", $$v)
                                          },
                                          expression: "setting.enabled"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: setting.enabled,
                                            expression: "setting.enabled"
                                          },
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: setting.color,
                                            expression: "setting.color"
                                          }
                                        ],
                                        attrs: {
                                          type: "color",
                                          disabled: _vm.wheelConfig.isAdvanced
                                        },
                                        domProps: { value: setting.color },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              setting,
                                              "color",
                                              $event.target.value
                                            )
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                }),
                                0
                              )
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c("hr"),
                        _vm._v(" "),
                        _c("wheelCenterImagePicker", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.browserIsIE,
                              expression: "!browserIsIE"
                            }
                          ],
                          attrs: {
                            imageSrc: _vm.centerImageSrc,
                            pictureType: _vm.wheelConfig.pictureType,
                            galleryPicture: _vm.wheelConfig.galleryPicture,
                            customPictureName:
                              _vm.wheelConfig.customPictureName,
                            centerText: _vm.wheelConfig.centerText,
                            isAdvanced: _vm.wheelConfig.isAdvanced
                          },
                          on: {
                            "set-center-no-image": _vm.setCenterNoImage,
                            "set-center-gallery-image":
                              _vm.setCenterGalleryImage,
                            "set-center-text": _vm.setCenterText,
                            "set-center-uploaded-image":
                              _vm.setCenterUploadedImage,
                            "set-color-values": _vm.setColorValues
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "columns is-mobile is-vcentered" },
                          [
                            _c("div", { staticClass: "column is-narrow" }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.$t("optionsdialog.Image size")) +
                                  "\n              "
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "column" },
                              [
                                _c(
                                  "b-select",
                                  {
                                    model: {
                                      value: _vm.wheelConfig.hubSize,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.wheelConfig,
                                          "hubSize",
                                          $$v
                                        )
                                      },
                                      expression: "wheelConfig.hubSize"
                                    }
                                  },
                                  _vm._l(_vm.hubSizes, function(hubSize) {
                                    return _c(
                                      "option",
                                      {
                                        key: hubSize,
                                        domProps: { value: hubSize }
                                      },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(hubSize) +
                                            "\n                  "
                                        )
                                      ]
                                    )
                                  }),
                                  0
                                )
                              ],
                              1
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.wheelConfig.type == "image",
                            expression: "wheelConfig.type=='image'"
                          }
                        ]
                      },
                      [
                        _c("wheelBackgroundImagePicker", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.browserIsIE,
                              expression: "!browserIsIE"
                            }
                          ],
                          attrs: { imageSrc: _vm.backgroundImageSrc },
                          on: {
                            "set-background-gallery-image":
                              _vm.setBackgroundGalleryImage,
                            "set-background-uploaded-image":
                              _vm.setBackgroundUploadedImage
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("hr"),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "columns is-mobile is-vcentered" },
                      [
                        _c("div", { staticClass: "column" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("optionsdialog.Background color")) +
                              "\n            "
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "column" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.wheelConfig.pageBackgroundColor,
                                expression: "wheelConfig.pageBackgroundColor"
                              }
                            ],
                            attrs: { type: "color" },
                            domProps: {
                              value: _vm.wheelConfig.pageBackgroundColor
                            },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.wheelConfig,
                                  "pageBackgroundColor",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "column" },
                          [
                            _c(
                              "b-checkbox",
                              {
                                model: {
                                  value: _vm.wheelConfig.drawOutlines,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.wheelConfig,
                                      "drawOutlines",
                                      $$v
                                    )
                                  },
                                  expression: "wheelConfig.drawOutlines"
                                }
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.$t("optionsdialog.Contours")) +
                                    "\n              "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ]
                    )
                  ]
                )
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "footer",
          {
            staticClass: "modal-card-foot",
            staticStyle: { "justify-content": "flex-end" }
          },
          [
            _c(
              "b-button",
              {
                attrs: { size: "is-medium" },
                on: {
                  click: function($event) {
                    return _vm.close()
                  }
                }
              },
              [
                _vm._v(
                  "\n        " + _vm._s(_vm.$t("common.Cancel")) + "\n      "
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "b-button",
              {
                attrs: { size: "is-medium", type: "is-primary" },
                on: { click: _vm.saveOptions }
              },
              [_vm._v("\n        " + _vm._s(_vm.$t("common.OK")) + "\n      ")]
            )
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }