var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "columns is-mobile is-vcentered" }, [
      _c("div", { staticClass: "column is-narrow" }, [
        _vm._v(
          "\n      " +
            _vm._s(_vm.$t("optionsdialog.Wheel background image")) +
            "\n    "
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "column" },
        [
          _c(
            "b-dropdown",
            {
              ref: "dropDown",
              attrs: {
                "append-to-body": "",
                "aria-role": "menu",
                scrollable: "",
                "max-height": "400",
                "trap-focus": ""
              },
              scopedSlots: _vm._u([
                {
                  key: "trigger",
                  fn: function({ active }) {
                    return [
                      _c(
                        "b-button",
                        {
                          staticStyle: { height: "50px" },
                          attrs: {
                            "icon-right": active ? "caret-up" : "caret-down"
                          }
                        },
                        [
                          _c("img", {
                            staticClass: "preview-image",
                            attrs: { src: _vm.imageSrc }
                          })
                        ]
                      )
                    ]
                  }
                }
              ]),
              model: {
                value: _vm.dropdownItem,
                callback: function($$v) {
                  _vm.dropdownItem = $$v
                },
                expression: "dropdownItem"
              }
            },
            [
              _vm._v(" "),
              _c(
                "b-dropdown-item",
                { key: "upload", attrs: { custom: "", value: "upload" } },
                [
                  _c(
                    "b-upload",
                    {
                      ref: "upload-button",
                      attrs: { accept: "image/*" },
                      model: {
                        value: _vm.uploadedCoverImage,
                        callback: function($$v) {
                          _vm.uploadedCoverImage = $$v
                        },
                        expression: "uploadedCoverImage"
                      }
                    },
                    [
                      _c("a", { staticClass: "button" }, [
                        _c("i", { staticClass: "fas fa-upload" }),
                        _vm._v(
                          "\n               \n              " +
                            _vm._s(_vm.$t("optionsdialog.Upload image")) +
                            "\n            "
                        )
                      ])
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm._l(_vm.coverImages, function(pic) {
                return [
                  _c(
                    "b-dropdown-item",
                    { key: pic.fileName, attrs: { value: pic } },
                    [
                      _c("img", {
                        staticClass: "preview-image",
                        attrs: { src: pic.fileName }
                      }),
                      _vm._v(
                        "\n             \n            " +
                          _vm._s(pic.title) +
                          "\n          "
                      )
                    ]
                  )
                ]
              })
            ],
            2
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }