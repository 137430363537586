var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "columns is-mobile is-vcentered" }, [
      _c("div", { staticClass: "column is-2" }, [
        _vm._v("\n      " + _vm._s(_vm.$t("optionsdialog.Sound")) + "\n    ")
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "column is-half is-half-mobile" },
        [
          _c(
            "b-select",
            {
              model: {
                value: _vm.myDuringSpinSound,
                callback: function($$v) {
                  _vm.myDuringSpinSound = $$v
                },
                expression: "myDuringSpinSound"
              }
            },
            [
              _vm._l(Object.keys(_vm.duringSpinSounds), function(category) {
                return [
                  _c(
                    "optgroup",
                    { attrs: { label: _vm.translateIfNeeded(category) } },
                    [
                      _vm._l(
                        Object.keys(_vm.duringSpinSounds[category]),
                        function(soundName) {
                          return [
                            _c(
                              "option",
                              {
                                key:
                                  _vm.duringSpinSounds[category][soundName]
                                    .value,
                                domProps: {
                                  value:
                                    _vm.duringSpinSounds[category][soundName]
                                      .value
                                }
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.translateIfNeeded(soundName)) +
                                    "\n              "
                                )
                              ]
                            )
                          ]
                        }
                      )
                    ],
                    2
                  )
                ]
              })
            ],
            2
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "column" },
        [
          _c(
            "b-button",
            {
              attrs: { disabled: !_vm.playbackEnabled },
              on: { click: _vm.playDuringSpinSound }
            },
            [_c("i", { staticClass: "fas fa-play" })]
          ),
          _vm._v(" "),
          _c(
            "b-button",
            {
              attrs: { disabled: !_vm.playbackEnabled },
              on: { click: _vm.stopDuringSpinSound }
            },
            [_c("i", { staticClass: "fas fa-stop" })]
          )
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "columns is-mobile is-vcentered" }, [
      _c("div", { staticClass: "column is-2" }, [
        _vm._v("\n      " + _vm._s(_vm.$t("optionsdialog.Volume")) + "\n    ")
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "column" },
        [
          _c(
            "b-slider",
            {
              attrs: { min: 0, max: 100 },
              model: {
                value: _vm.myDuringSpinSoundVolume,
                callback: function($$v) {
                  _vm.myDuringSpinSoundVolume = $$v
                },
                expression: "myDuringSpinSoundVolume"
              }
            },
            [
              _vm._l([0, 50, 100], function(val) {
                return [
                  _c("b-slider-tick", { key: val, attrs: { value: val } }, [
                    _vm._v(_vm._s(val))
                  ])
                ]
              })
            ],
            2
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }