var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { height: "4em" } },
    [
      _vm._v("\n  " + _vm._s(_vm.title) + "\n  "),
      _c("transition", { attrs: { name: "fade", mode: "in-out" } }, [
        _vm.displayFirstDiv
          ? _c(
              "p",
              {
                staticClass: "is-size-2",
                staticStyle: { position: "absolute" }
              },
              [_vm._v("\n      " + _vm._s(_vm.formattedNumber) + "\n    ")]
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("transition", { attrs: { name: "fade", mode: "in-out" } }, [
        !_vm.displayFirstDiv
          ? _c(
              "p",
              {
                staticClass: "is-size-2",
                staticStyle: { position: "absolute" }
              },
              [_vm._v("\n      " + _vm._s(_vm.formattedNumber) + "\n    ")]
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }