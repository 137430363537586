var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: { active: _vm.showWarning, width: 640, scroll: "keep" },
          on: {
            "update:active": function($event) {
              _vm.showWarning = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "modal-card", staticStyle: { width: "auto" } },
            [
              _c("header", { staticClass: "modal-card-head" }, [
                _c("p", { staticClass: "modal-card-title" }, [
                  _c("i", { staticClass: "fa fa-share-alt" }),
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("sharedialog.Shareable link")) +
                      "\n        "
                  )
                ])
              ]),
              _vm._v(" "),
              _c(
                "section",
                { staticClass: "modal-card-body can-go-dark" },
                [
                  _c("p", [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("sharedialog.If you continue")) +
                        "\n          " +
                        _vm._s(
                          _vm.$t("sharedialog.This link will work for anyone")
                        ) +
                        "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c("p", { staticStyle: { "margin-top": "10px" } }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$t(
                            "sharedialog.We want this website to be safe place for everyone"
                          )
                        ) +
                        "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "p",
                    { staticStyle: { "margin-top": "10px" } },
                    [
                      _c("router-link", { attrs: { to: "/faq/terms" } }, [
                        _vm._v(_vm._s(_vm.$t("appInfo.Terms of service")))
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-button",
                    {
                      staticStyle: {
                        "margin-top": "10px",
                        "background-color": "#eff5fb",
                        color: "#296fa8"
                      },
                      attrs: { size: "is-default" },
                      on: {
                        click: function($event) {
                          return _vm.enter_LoadingSharedWheels()
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("sharedialog.See my shared wheels")) +
                          "\n        "
                      )
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "footer",
                {
                  staticClass: "modal-card-foot",
                  staticStyle: { "justify-content": "flex-end" }
                },
                [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "is-medium" },
                      on: {
                        click: function($event) {
                          return _vm.enter_Inactive()
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("common.Cancel")) +
                          "\n        "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-button",
                    {
                      ref: "continueButton1",
                      attrs: { size: "is-medium", type: "is-primary" },
                      on: {
                        click: function($event) {
                          return _vm.enter_UserIsSettingOptions()
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("common.Continue")) +
                          "\n        "
                      )
                    ]
                  )
                ],
                1
              )
            ]
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: { active: _vm.showSharedWheels, scroll: "keep" },
          on: {
            "update:active": function($event) {
              _vm.showSharedWheels = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "modal-card", staticStyle: { width: "auto" } },
            [
              _c("header", { staticClass: "modal-card-head" }, [
                _c("p", { staticClass: "modal-card-title" }, [
                  _c("i", { staticClass: "fa fa-share-alt" }),
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("sharedialog.My shared wheels")) +
                      "\n        "
                  )
                ])
              ]),
              _vm._v(" "),
              _c("section", { staticClass: "modal-card-body can-go-dark" }, [
                _c("p", [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("sharedialog.Only wheels shared after")) +
                      "\n        "
                  )
                ]),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _c(
                  "table",
                  { staticClass: "table can-go-dark" },
                  [
                    !_vm.noSharedWheels
                      ? _c("tr", [
                          _c("th", [
                            _vm._v(_vm._s(_vm.$t("common.Wheel title")))
                          ]),
                          _vm._v(" "),
                          _c("th", [
                            _vm._v(_vm._s(_vm.$t("sharedialog.Link")))
                          ]),
                          _vm._v(" "),
                          _c("th"),
                          _vm._v(" "),
                          _vm.$mq == "desktop"
                            ? _c("th", [
                                _vm._v(_vm._s(_vm.$t("sharedialog.Created")))
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("th", [
                            _vm._v(_vm._s(_vm.$t("sharedialog.Visits")))
                          ])
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm._l(_vm.sharedWheels, function(wheel) {
                      return _c("tr", { key: wheel.path }, [
                        _c("td", [_vm._v(_vm._s(wheel.config.title))]),
                        _vm._v(" "),
                        _c("td", { staticStyle: { "white-space": "nowrap" } }, [
                          _c(
                            "a",
                            { attrs: { href: _vm.getWheelLink(wheel.path) } },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(wheel.path) +
                                  "\n              "
                              )
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "td",
                          [
                            _c(
                              "b-button",
                              {
                                attrs: { type: "is-light" },
                                on: {
                                  click: function($event) {
                                    _vm.copyToClipboard(
                                      _vm.getWheelLink(wheel.path)
                                    )
                                  }
                                }
                              },
                              [_c("i", { staticClass: "far fa-clipboard" })]
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.$mq == "desktop"
                          ? _c(
                              "td",
                              { staticStyle: { "white-space": "nowrap" } },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm._f("timeago")(wheel.created)) +
                                    "\n            "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c("td", { staticClass: "has-text-right" }, [
                          _vm._v(
                            _vm._s(_vm._f("localestring")(wheel.readCount))
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "td",
                          [
                            _c(
                              "b-button",
                              {
                                attrs: { type: "is-light" },
                                on: {
                                  click: function($event) {
                                    return _vm.enter_confirmingDelete(
                                      wheel.path
                                    )
                                  }
                                }
                              },
                              [
                                _c("i", { staticClass: "far fa-trash-alt" }),
                                _vm._v(" "),
                                _vm.$mq == "desktop"
                                  ? _c("span", [
                                      _vm._v(
                                        " " + _vm._s(_vm.$t("common.Delete"))
                                      )
                                    ])
                                  : _vm._e()
                              ]
                            )
                          ],
                          1
                        )
                      ])
                    }),
                    _vm._v(" "),
                    _vm.noSharedWheels
                      ? _c("tr", [
                          _c("td", [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("sharedialog.No wheels found")) +
                                "\n            "
                            )
                          ])
                        ])
                      : _vm._e()
                  ],
                  2
                )
              ]),
              _vm._v(" "),
              _c(
                "footer",
                {
                  staticClass: "modal-card-foot",
                  staticStyle: { "justify-content": "flex-end" }
                },
                [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "is-medium" },
                      on: {
                        click: function($event) {
                          return _vm.enter_Inactive()
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("common.Close")) +
                          "\n        "
                      )
                    ]
                  )
                ],
                1
              )
            ]
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: { active: _vm.showOptions, width: 640, scroll: "keep" },
          on: {
            "update:active": function($event) {
              _vm.showOptions = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "modal-card", staticStyle: { width: "auto" } },
            [
              _c("header", { staticClass: "modal-card-head" }, [
                _c("p", { staticClass: "modal-card-title" }, [
                  _c("i", { staticClass: "fa fa-share-alt" }),
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("sharedialog.Shareable link")) +
                      "\n        "
                  )
                ])
              ]),
              _vm._v(" "),
              _c(
                "section",
                { staticClass: "modal-card-body can-go-dark" },
                [
                  _c("div", { staticClass: "field" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("common.Wheel title")) +
                        "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c("b-input", {
                    ref: "wheelTitleField",
                    attrs: { maxlength: "50" },
                    nativeOn: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.enter_CreatingLink()
                      }
                    },
                    model: {
                      value: _vm.wheelTitle,
                      callback: function($$v) {
                        _vm.wheelTitle = $$v
                      },
                      expression: "wheelTitle"
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "field" },
                    [
                      _c(
                        "b-checkbox",
                        {
                          model: {
                            value: _vm.showTitle,
                            callback: function($$v) {
                              _vm.showTitle = $$v
                            },
                            expression: "showTitle"
                          }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.$t("sharedialog.Show title to people")
                              ) +
                              "\n          "
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "field" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$t(
                            "sharedialog.What should a person be able to do"
                          )
                        ) +
                        "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "field" },
                    [
                      _c(
                        "b-radio",
                        {
                          attrs: { "native-value": true },
                          model: {
                            value: _vm.copyableWheel,
                            callback: function($$v) {
                              _vm.copyableWheel = $$v
                            },
                            expression: "copyableWheel"
                          }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.$t(
                                  "sharedialog.They should be able to spin the wheel and"
                                )
                              ) +
                              "\n          "
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "field" },
                    [
                      _c(
                        "b-radio",
                        {
                          attrs: { "native-value": false },
                          model: {
                            value: _vm.copyableWheel,
                            callback: function($$v) {
                              _vm.copyableWheel = $$v
                            },
                            expression: "copyableWheel"
                          }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.$t(
                                  "sharedialog.They should only be able to spin the wheel"
                                )
                              ) +
                              "\n          "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "footer",
                {
                  staticClass: "modal-card-foot",
                  staticStyle: { "justify-content": "flex-end" }
                },
                [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "is-medium" },
                      on: {
                        click: function($event) {
                          return _vm.enter_Inactive()
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("common.Cancel")) +
                          "\n        "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "is-medium", type: "is-primary" },
                      on: {
                        click: function($event) {
                          return _vm.enter_CreatingLink()
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("common.Continue")) +
                          "\n        "
                      )
                    ]
                  )
                ],
                1
              )
            ]
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: { active: _vm.showLink, width: 640, scroll: "keep" },
          on: {
            "update:active": function($event) {
              _vm.showLink = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "modal-card", staticStyle: { width: "auto" } },
            [
              _c("header", { staticClass: "modal-card-head" }, [
                _c("p", { staticClass: "modal-card-title" }, [
                  _c("i", { staticClass: "fa fa-share-alt" }),
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("sharedialog.Shareable link")) +
                      "\n        "
                  )
                ])
              ]),
              _vm._v(" "),
              _c("section", { staticClass: "modal-card-body can-go-dark" }, [
                _c("p", [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("sharedialog.Link to this wheel")) +
                      "\n        "
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "columns" }, [
                  _c(
                    "div",
                    { staticClass: "column is-8" },
                    [
                      _c("b-input", {
                        attrs: { id: "sharableLinkText" },
                        model: {
                          value: _vm.sharableLink,
                          callback: function($$v) {
                            _vm.sharableLink = $$v
                          },
                          expression: "sharableLink"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "column" },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: { type: "is-light" },
                          on: { click: _vm.copyLink }
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("sharedialog.Copy link")) +
                              "\n            "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$t("sharedialog.This link will work for anyone")
                      ) +
                      "\n        "
                  )
                ])
              ]),
              _vm._v(" "),
              _c(
                "footer",
                {
                  staticClass: "modal-card-foot",
                  staticStyle: { "justify-content": "flex-end" }
                },
                [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "is-medium" },
                      on: {
                        click: function($event) {
                          return _vm.enter_Inactive()
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("common.Close")) +
                          "\n        "
                      )
                    ]
                  )
                ],
                1
              )
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }