var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-tabs",
    {
      staticStyle: { height: "100%" },
      attrs: { type: "is-boxed", size: "is-small" },
      model: {
        value: _vm.activeTab,
        callback: function($$v) {
          _vm.activeTab = $$v
        },
        expression: "activeTab"
      }
    },
    [
      _c(
        "b-tab-item",
        {
          staticClass: "my-tab-item",
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function() {
                return [
                  _c(
                    "span",
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("nameTabs.Entries")) +
                          "\n        "
                      ),
                      _c("counter-tag", {
                        attrs: { number: _vm.$store.getters.entryCount }
                      })
                    ],
                    1
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _vm._v(" "),
          _c("textboxbuttons", {
            on: {
              "reset-wheel-rotation": function($event) {
                return _vm.$emit("reset-wheel-rotation")
              }
            }
          }),
          _vm._v(" "),
          !_vm.wheelIsAdvanced ? _c("textbox") : _vm._e(),
          _vm._v(" "),
          _vm.wheelIsAdvanced ? _c("advancedsliceeditor") : _vm._e(),
          _vm._v(" "),
          _c("app-info")
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-tab-item",
        {
          staticClass: "my-tab-item",
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function() {
                return [
                  _c(
                    "span",
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("nameTabs.Results")) +
                          "\n        "
                      ),
                      _c("counter-tag", {
                        attrs: { number: _vm.$store.getters.winnerCount }
                      })
                    ],
                    1
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _vm._v(" "),
          _c("b-field", { attrs: { grouped: "", "group-multiline": "" } }, [
            _c(
              "p",
              { staticClass: "control" },
              [
                _c(
                  "b-button",
                  {
                    attrs: { size: "is-small", type: "is-light" },
                    on: { click: _vm.sortWinners }
                  },
                  [
                    _c("i", { staticClass: "fas fa-sort-alpha-up" }),
                    _vm._v(
                      " " + _vm._s(_vm.$t("textboxbuttons.Sort")) + "\n        "
                    )
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "p",
              { staticClass: "control" },
              [
                _c(
                  "b-button",
                  {
                    attrs: { size: "is-small", type: "is-light" },
                    on: { click: _vm.clearWinners }
                  },
                  [
                    _c("i", { staticClass: "fas fa-times" }),
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("nameTabs.Clear the list")) +
                        "\n        "
                    )
                  ]
                )
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("winnertextbox")
        ],
        1
      ),
      _vm._v(" "),
      _c("b-tab-item", {
        staticClass: "my-tab-item",
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function() {
              return [
                _c("span", [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("nameTabs.Hide")) + "\n      "
                  )
                ])
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }