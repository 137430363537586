var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showAd
    ? _c("div", [
        _c("div", { staticClass: "enthusiast-adhesion" }, [
          _c(
            "div",
            {
              staticClass: "enthusiast-close-button",
              on: {
                click: function($event) {
                  return _vm.hideAd()
                }
              }
            },
            [_vm._v("X")]
          ),
          _vm._v(" "),
          _c("div", { attrs: { id: "div-gpt-ad-sticky-bottom" } })
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }