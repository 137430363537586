var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    { staticStyle: { color: "#CCC" } },
    [
      _c(
        "b-tooltip",
        { attrs: { type: "is-light", multilined: "", label: _vm.title } },
        [_c("i", { staticClass: "fas fa-question-circle" })]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }